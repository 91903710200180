import React from "react";
import Narrow from "../Components/Common/Narrow";
import { useState, useEffect } from "react";
import Getintouch from "../Components/Getintouch";

export default function Brainstorming() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div>
      <div className="darkbg py-1 mb-32">
        <Narrow>
          <div className="mb-36 mt-24 max-xl:mt-3">
            <div>
              <div className="firstbtnsec ">
                <span className="abouta1 bg-white">Brainstorming</span>
              </div>
            </div>
            <div
              className={`header ${isVisible ? "fade-in" : ""}`}
              style={{ marginTop: "10px" }}
            >
              <div>
                <p className="aboutheader1 max-xl:text-3xl font-semibold text-white">
                  Brainstorming your ideas
                </p>
                <p className="aboutheader1 max-xl:text-3xl font-semibold"> </p>

                <p className="aboutheader2 max-xl:px-3 text-2xl text-white leading-10">
                  By fostering a free-flowing exchange of thoughts,
                  brainstorming stimulates <br /> innovation, problem-solving,
                  and fresh perspectives.
                </p>
              </div>
              <div className="bg-blue-600 w-1/6 max-xl:w-1/2 mx-auto text-center rounded-lg mt-10">
                <button className="text-white p-3">
                  Book an appointment now
                </button>
              </div>
            </div>
          </div>
        </Narrow>
      </div>

      <Narrow>
        <img
          src="https://wpriverthemes.com/synck/wp-content/uploads/2024/01/how-we-do-single.jpg"
          alt=""
          className="mb-32"
        />
      </Narrow>

      <Narrow>
        <div className="background rounded-lg p-32 max-xl:p-3">
          <h1 className="text-5xl font-bold mb-5">Introduction</h1>
          <p className="text-2xl leading-10 text-gray-700">
            In today's fast-paced and technologically advanced world, businesses
            rely heavily on Information Technology (IT) services to remain
            competitive, innovative, and efficient. From streamlining operations
            to enhancing customer experience <br />
            <br />
            IT services play a crucial role in transforming businesses across
            all industries. In this blog, we will explore the significance of IT
            services, the key benefits they offer, and how they can empower your
            business to reach new heights. <br />
            <br />
            IT services encompass a wide range of solutions aimed at managing,
            optimizing, and supporting the technology infrastructure of a
            business. This includes hardware and software management, network
            administration, cybersecurity, data backup and recovery, cloud
            services, and more. Whether you run a small startup or a large
            enterprise, leveraging the right IT services can have a profound
            impact on your business's success. One of the primary benefits of
            adopting IT services is their ability to streamline various business
            operations. Automated processes, such as enterprise resource
            planning (ERP) systems, can integrate different departments and make
            data accessible in real-time.
          </p>

          <img
            src="https://wpriverthemes.com/synck/wp-content/uploads/2023/12/blog-details-2.jpg"
            className="mt-10"
            alt=""
          />

          <p className="text-2xl leading-10 text-gray-700 mt-10">
            As businesses increasingly rely on digital technologies, the risk of
            cyber threats also grows. A robust IT service provider will
            implement cutting-edge cybersecurity measures to safeguard your
            valuable data, sensitive information, and intellectual property.
            From firewall protection to regular vulnerability assessments, a
            comprehensive security strategy ensures that your business stays
            protected against cyberattacks.
          </p>

          <div className="mt-20 text-3xl font-normal text-black px-32 border-l-8 border-blue-700 py-16 max-xl:p-3">
            <i>
              In a dynamic business environment, scalability is crucial. IT
              services provide the flexibility to scale up or down your
              resources based on changing business needs. Cloud services, for
              instance, allow seamless expansion of storage and computational
              power
            </i>

            <div className="flex gap-4 mt-16">
              <div className="">
                <svg
                  className="m-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  height={40}
                  viewBox="0 0 448 512"
                >
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>
              </div>
              <div>
                <p className="text-lg font-semibold">Serana Belluci</p>
                <p className="text-lg font-normal">Product Designer</p>
              </div>
            </div>
          </div>

          <p className="text-2xl leading-10 text-gray-700 mt-20">
            Customer experience has become a key differentiator in today's
            competitive landscape. IT services enable businesses to personalize
            customer interactions, provide efficient support through various
            channels, and offer seamless online experiences. <br />
            <br />
            IT services facilitate data collection, storage, analysis, and
            visualization, turning raw information into actionable intelligence.
            By harnessing the power of data analytics, businesses can identify
            trends, customer preferences, and areas for improvement, leading to
            more effective strategies and increased profitability. Disruptions,
            such as natural disasters or system failures, can severely impact a
            business's operations. IT services include robust disaster recovery
            and backup solutions, ensuring that critical data is protected and
            can be swiftly recovered in case of any unforeseen events. This
            level of preparedness helps maintain business continuity and
            minimizes downtime, <br />
            <br />
            Whether it's through chatbots, mobile apps, or responsive websites,
            IT services empower businesses to exceed customer expectations and
            build lasting relationships. Data is a goldmine of valuable insights
            that can help businesses make informed decisions.
          </p>

          <h1 className="text-5xl font-bold mb-5 mt-20">
            Ensuring Business Continuitiy
          </h1>
          <p className="text-2xl leading-10 text-gray-700 mt-16">
            Disruptions, such as natural disasters or system failures, can
            severely impact a business's operations. IT services include robust
            disaster recovery and backup solutions, ensuring that critical data
            is protected and can be swiftly recovered in case of any unforeseen
            events. <br />
            <br />
            This level of preparedness helps maintain business continuity and
            minimizes downtime, thus safeguarding your reputation and revenue.
            This includes hardware and software management, network
            administration, cybersecurity, data backup and recovery, cloud
            services, and more. Whether you run a small startup or a large
            enterprise, leveraging the right IT services can have a profound
            impact on your business's success. One of the primary benefits of
            adopting IT services is their ability to streamline various business
            operations.From firewall protection to regular vulnerability
            assessments provide efficient support through various channels, and
            offer seamless online experiences.
          </p>

          <h1 className="text-5xl font-bold mb-5 mt-20">
            Enhancing Cybersecurity
          </h1>
          <p className="text-2xl leading-10 text-gray-700 mt-16">
          For businesses without an in-house IT team, managed IT support services are invaluable. A reliable IT service provider offers proactive monitoring, maintenance, and troubleshooting for your IT systems. They can identify and resolve potential issues before they escalate, <br />
            <br />
            Virtualization is the process of creating virtual versions of hardware or software resources, allowing multiple applications or operating systems to run on a single physical server. This technology optimizes resource utilization, reduces hardware costs, and simplifies IT management. By embracing virtualization services, businesses can enhance flexibility, increase efficiency, and easily deploy new applications without the need for additional physical hardware. Data-driven decision-making is a key factor in modern business success. IT services provide advanced business intelligence and analytics tools that can process and analyze vast amounts of data, extracting meaningful insights.
          </p>
        </div>
      </Narrow>

      <div className="whybetter py-36 max-xl:p-0 mt-32 mb-32">
        <Narrow>
          <div>
            <div className="flex max-xl:flex-col">
              <div className="whybetterimg w-1/2 max-xl:w-full">
                <img
                  src="https://wpriverthemes.com/synck/wp-content/uploads/2024/01/about-service-3.png"
                  alt=""
                  className="bettertanother h-full"
                />
              </div>
              <div className="whybettertext w-1/2 max-xl:w-full">
                <h1 className="text-5xl font-bold">
                  Why our services are better than others?
                </h1>
                <h5 className="text-xl text-gray-200">
                  We don't believe in a one-size-fit-all approach. Our services
                  are carefully customized to suit your specific need, ensuring
                  you to achieve your goals.
                </h5>
                <h5 className="text-xl text-gray-200">
                  We believe in delivering value that extends your beyond the
                  immediate project. Our services are designed to provide a
                  long-term benefits.
                </h5>

                <div>
                  <div className="flex max-xl:flex-col gap-10">
                    <div className="tasksign">
                      <img
                        src="https://ik.imagekit.io/2q7cgnqzi/icon/check.png?updatedAt=1711451555962"
                        alt=""
                        className="righticonimagesize w-10"
                      />
                      <h5 className="text-xl my-auto text-gray-200">
                        PPD Development{" "}
                      </h5>
                    </div>

                    <div className="tasksign">
                      <img
                        src="https://ik.imagekit.io/2q7cgnqzi/icon/check.png?updatedAt=1711451555962"
                        alt=""
                        className="righticonimagesize w-10"
                      />
                      <h5 className="text-xl my-auto text-gray-200">
                        Quick Response{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Narrow>
      </div>

      <Getintouch/>
    </div>
  );
}
